import flagsmith from 'flagsmith';

export const LEGACY_IS_INTERNAL_FLAG: FeatureFlag = 'legacy-is-internal';

export const LEGACY_UI_MODULES: FeatureFlag = 'legacy-ui-modules';

export const COMPANY_COLLECTION_ENABLED: FeatureFlag =
  'company-collection-enabled';

export const PWA: FeatureFlag = 'pwa';

export const CORE_MAINTENANCE_MODE: FeatureFlag = 'core-maintenance-mode';

export const DISABLE_DATA_SOURCE_FIELDS: FeatureFlag =
  'disable-data-source-fields';

export const EMBED_COMPONENT_ENABLED: FeatureFlag = 'embed-component-enabled';

export const LOOKUP_FIELDS: FeatureFlag = 'lookup-fields';

export const STACKED_BAR_CHART: FeatureFlag = 'stacked-bar-chart';

export const HUBSPOT_INTEGRATION: FeatureFlag = 'hubspot-integration';

export const BARCODE_SCANNER: FeatureFlag = 'barcode_scanner';

export const PIVOT_TABLE_FLAG: FeatureFlag = 'pivot_table';

export const DYNAMIC_MULTI_SAML = 'dynamic-multi-saml';

export const TWO_FACTOR_AUTHENTICATION = 'two-factor-authentication';

export const SPACES_FLAG: FeatureFlag = 'spaces';

export type FeatureFlag =
  | 'company-collection-enabled'
  | 'core-maintenance-mode'
  | 'disable-data-source-fields'
  | 'embed-component-enabled'
  | 'hubspot-integration'
  | 'legacy-is-internal'
  | 'legacy-ui-modules'
  | 'lookup-fields'
  | 'pwa'
  | 'stacked-bar-chart'
  | 'barcode_scanner'
  | 'pivot_table'
  | 'dynamic-multi-saml'
  | 'two-factor-authentication'
  | 'spaces';

const useHasFeatureFlag = (feature: FeatureFlag) =>
  flagsmith.hasFeature(feature);

export default useHasFeatureFlag;
